var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Tên báo cáo","label-for":"input-1"}},[_c('validation-provider',{key:"tenBaoCao",attrs:{"rules":{ required: true },"name":"tenBaoCao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"tenBaoCao",staticClass:"style-chooser",attrs:{"options":_vm.lvCbx,"placeholder":"Chọn tên báo cáo","reduce":function (np) { return np.id; },"label":"name","filter":_vm.onFilterSelect,"disabled":_vm.dataForm.id ? true : false},on:{"input":function (e) { return _vm.dataForm.baoCaoId = _vm.data.baoCaoId; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Không có dữ liệu ")]},proxy:true}],null,true),model:{value:(_vm.data.baoCaoId),callback:function ($$v) {_vm.$set(_vm.data, "baoCaoId", $$v)},expression:"data.baoCaoId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Đơn vị xem báo cáo","label-for":"input-1"}},[_c('validation-provider',{key:"donViId",attrs:{"rules":{ required: true },"name":"donViId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"donViId",attrs:{"id":"linhVuc","options":_vm.dvCbx,"placeholder":"Chọn đơn vị xem báo cáo","normalizer":_vm.normalizer,"limit":1,"limit-text":function (count) { return (" + " + count + " lựa chọn"); },"value-consists-of":"LEAF_PRIORITY","no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name']},on:{"input":function($event){return _vm.changeDonVi()},"select":_vm.selectItem},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.data.donViId),callback:function ($$v) {_vm.$set(_vm.data, "donViId", $$v)},expression:"data.donViId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Vai trò xem báo cáo","label-for":"input-1"}},[_c('validation-provider',{key:"tenVaiTro",attrs:{"rules":{ required: true },"name":"tenVaiTro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenVaiTro",attrs:{"id":"linhVuc","default-options":_vm.nndCbx,"placeholder":"Chọn vai trò xem báo cáo","limit":0,"multiple":"","disabled":_vm.data.donViId ? false : true,"limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizerThuong,"match-keys":['label', 'label2'],"load-options":_vm.onChange,"async":true,"clear-on-select":true},on:{"input":function (e) { return _vm.dataForm.dataVaiTro = _vm.data.dataVaiTro; },"select":function($event){return _vm.$refs.tenVaiTro.resetSearchQuery()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.data.dataVaiTro),callback:function ($$v) {_vm.$set(_vm.data, "dataVaiTro", $$v)},expression:"data.dataVaiTro"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Đơn vị lấy dữ liệu","label-for":"input-1"}},[_c('validation-provider',{key:"vaiTroDuLieu",attrs:{"rules":{ required: true },"name":"vaiTroDuLieu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"vaiTroDuLieu",attrs:{"options":_vm.dvCbx,"placeholder":"Chọn đơn vị lấy dữ liệu","normalizer":_vm.normalizer,"limit":1,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"value-consists-of":"ALL","no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name']},on:{"input":function (e) { return _vm.dataForm.dataDonViLayDuLieu = _vm.data.dataDonViLayDuLieu; }},model:{value:(_vm.data.dataDonViLayDuLieu),callback:function ($$v) {_vm.$set(_vm.data, "dataDonViLayDuLieu", $$v)},expression:"data.dataDonViLayDuLieu"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }