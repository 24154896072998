<template>
  <phan-quyen-bao-cao-don-vi />
</template>

<script>
import PhanQuyenBaoCaoDonVi from '@/modules/mot-cua/components/pages/bao-cao-thong-ke/PhanQuyenBaoCaoDonVi.vue'

export default {
  name: 'TrangChu',
  components: {
    PhanQuyenBaoCaoDonVi,
  },
}
</script>

<style scoped></style>
