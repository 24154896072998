var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('action-btn',{attrs:{"selected-number":_vm.selectedNumber,"actions-permission":[_vm.checkQuyenThemMoi, _vm.checkQuyenXoa]},on:{"add-item":_vm.addCommom,"delete-item":_vm.deleteCommom,"refresh-page":_vm.refreshPage}}),_c('good-table',{ref:"vgTable",staticClass:"mt-1",class:_vm.checkAllQuyen ? '' : 'fixed-column',attrs:{"columns":_vm.getColumns,"rows":_vm.rows,"total":_vm.total,"is-loading":_vm.isLoading},on:{"column-filter":_vm.columnFilter,"selected-item":_vm.selectedItem,"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"custom-row",fn:function(ref){
var props = ref.props;
return _c('div',{},[(props.column.field == 'dataTenVaiTro')?_c('span',_vm._l((props.row.dataTenVaiTro),function(item,idx){return _c('div',{key:(item + "_" + idx)},[_vm._v(" - "+_vm._s(item)+" ")])}),0):(props.column.field == 'dataTenDonViLayDuLieu')?_c('span',_vm._l((props.row.dataTenDonViLayDuLieu),function(item,idx){return _c('div',{key:(item + "_" + idx)},[_vm._v(" - "+_vm._s(item)+" ")])}),0):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])])}},{key:"custom-filter",fn:function(ref){
var props = ref.props;
return _c('div',{},[(props.column.field == 'tenBaoCao')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenBaoCao",staticClass:"vtreeselect-chooser",attrs:{"id":"linhVuc","default-options":_vm.bcCbx,"placeholder":"Chọn báo cáo","limit":0,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizerBaoCao,"match-keys":['label', 'label2'],"load-options":_vm.onChange,"async":true,"clear-on-select":true,"before-clear-all":_vm.clearTreeSelect},on:{"select":function($event){_vm.$refs.tenBaoCao.resetSearchQuery(); _vm.nextTickTreeSelect()},"deselect":function($event){return _vm.nextTickTreeSelect()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.payload.baoCaoId),callback:function ($$v) {_vm.$set(_vm.payload, "baoCaoId", $$v)},expression:"payload.baoCaoId"}})],1):(props.column.field == 'tenDonVi')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],staticClass:"vtreeselect-chooser",attrs:{"options":_vm.dvCbx,"placeholder":"Chọn vai trò","normalizer":_vm.normalizer,"match-keys":['label', 'name'],"multiple":true,"value-consists-of":"LEAF_PRIORITY","limit":0,"limit-text":function (count) { return ("và " + count + " vai trò"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu"},on:{"input":function($event){return _vm.columnFilter()}},model:{value:(_vm.payload.donViId),callback:function ($$v) {_vm.$set(_vm.payload, "donViId", $$v)},expression:"payload.donViId"}})],1):_vm._e()])}},{key:"actions",fn:function(ref){
var props = ref.props;
return _c('div',{},[(_vm.checkQuyenSua)?_c('edit-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer mr-1",attrs:{"title":"Cập nhật","size":"16"},on:{"click":function($event){return _vm.updateCommom(props.row)}}}):_vm._e(),(_vm.checkQuyenXoa)?_c('trash-2-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer",attrs:{"title":"Xóa","size":"16"},on:{"click":function($event){return _vm.deleteCommomTable(props.row)}}}):_vm._e()],1)}}])}),_c('common-modal',{ref:"commonModal",attrs:{"title":_vm.title,"size":_vm.size},on:{"handle-ok":_vm.handleOk,"handle-focus":_vm.handleFocusError}},[_c(_vm.componentName === 'XoaForm' ? '' : _vm.componentName,{ref:"componentName",tag:"component",attrs:{"data-form":_vm.dataForm}}),(_vm.isShow)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.commonModal.checkValidate()}}},[_vm._v(" Lưu ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.openModal}},[_vm._v(" Đóng ")])],1):_vm._e()],1),_c('common-modal',{ref:"commonnModal",attrs:{"title":"Xác nhận","size":"sm"}},[_vm._v(" Bạn có xác nhận đóng quá trình nhập liệu này không? "),(_vm.isShowFooter)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.$refs.commonModal.hideModal(),_vm.$refs.commonnModal.hideModal()}}},[_vm._v(" Đồng ý ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.$refs.commonnModal.hideModal()}}},[_vm._v(" Đóng ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }