<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tên báo cáo"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenBaoCao"
        :rules="{ required: true }"
        name="tenBaoCao"
      >
        <v-select
          ref="tenBaoCao"
          v-model="data.baoCaoId"
          class="style-chooser"
          :options="lvCbx"
          placeholder="Chọn tên báo cáo"
          :reduce="np => np.id"
          label="name"
          :filter="onFilterSelect"
          :disabled="dataForm.id ? true : false"
          @input="e => dataForm.baoCaoId = data.baoCaoId"
        >
          <template #no-options>
            Không có dữ liệu
          </template>
        </v-select>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Đơn vị xem báo cáo"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="donViId"
        :rules="{ required: true }"
        name="donViId"
      >
        <treeselect
          id="linhVuc"
          ref="donViId"
          v-model="data.donViId"
          v-format-v-select
          :options="dvCbx"
          placeholder="Chọn đơn vị xem báo cáo"
          :normalizer="normalizer"
          :limit="1"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          value-consists-of="LEAF_PRIORITY"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :match-keys="['label', 'name']"
          @input="changeDonVi()"
          @select="selectItem"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Vai trò xem báo cáo"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenVaiTro"
        :rules="{ required: true }"
        name="tenVaiTro"
      >
        <treeselect
          id="linhVuc"
          ref="tenVaiTro"
          v-model="data.dataVaiTro"
          v-format-v-select
          :default-options="nndCbx"
          placeholder="Chọn vai trò xem báo cáo"
          :limit="0"
          multiple
          :disabled="data.donViId ? false : true"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizerThuong"
          :match-keys="['label', 'label2']"
          :load-options="onChange"
          :async="true"
          :clear-on-select="true"
          @input="e => dataForm.dataVaiTro = data.dataVaiTro"
          @select="$refs.tenVaiTro.resetSearchQuery()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Đơn vị lấy dữ liệu"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="vaiTroDuLieu"
        :rules="{ required: true }"
        name="vaiTroDuLieu"
      >
        <treeselect
          ref="vaiTroDuLieu"
          v-model="data.dataDonViLayDuLieu"
          v-format-v-select
          :options="dvCbx"
          placeholder="Chọn đơn vị lấy dữ liệu"
          :normalizer="normalizer"
          :limit="1"
          multiple
          :limit-text="(count) => ` + ${count} lựa chọn`"
          value-consists-of="ALL"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :match-keys="['label', 'name']"
          @input="e => dataForm.dataDonViLayDuLieu = data.dataDonViLayDuLieu"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormGroup,
} from 'bootstrap-vue'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { removeDiacritical } from '@/utils/index'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import _debounce from 'lodash/debounce'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lvCbx: [],
      dvCbx: [],
      nndCbx: [],
      data: {
        baoCaoId: null,
        donViId: null,
        vaiTro: null,
        donViLayDuLieu: null,
        dataVaiTro: [],
        dataDonViLayDuLieu: [],
      },
    }
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.$refs.selectCommune.focus()
  //   })
  // },
  created() {
    let url = null
    if (this.dataForm.id) {
      url = END_POINTS.BAO_CAO.COMBOBOX
    } else {
      url = END_POINTS.BAO_CAO_DON_VI.DS_BAO_CAO
    }
    Promise.all([this.$axios.get(url), this.$axios.get(END_POINTS.CHUNG.DON_VI, { kieuDonVi: 1 })]).then(res => {
      if (res[0].data?.succeeded) {
        this.lvCbx = res[0].data.data
      }
      if (res[1].data?.succeeded) {
        const formatDonVi = _cloneDeep(res[1].data.data)
        this.dvCbx = this.listToTree(formatDonVi)
      }
      this.data = this.dataForm
    })
  },
  methods: {
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.nndCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDataNhomNguoiDung() {
      this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG).then(res => {
        if (res.data?.succeeded) {
          this.nndCbx = res.data.data
        }
      })
    },
    changeDonVi() {
      if (!this.data.donViId) {
        this.data.dataVaiTro = null
      }
      this.dataForm.donViId = this.data.donViId
      this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG, { donViId: this.data.donViId }).then(res => {
        if (res.data?.succeeded) {
          this.nndCbx = res.data.data
        }
      })
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    selectItem() {
      this.data.dataVaiTro = null
    },
    normalizer(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
          name: removeDiacritical(node.tenDonVi),
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
        name: removeDiacritical(node.tenDonVi),
      }
    },
    normalizerThuong(node) {
      return {
        id: node.id,
        label: node.name,
        name: removeDiacritical(node.tenDonVi),
      }
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
