<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
    >
      <div
        slot="custom-row"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'dataTenVaiTro'">
          <div
            v-for="(item, idx) in props.row.dataTenVaiTro"
            :key="`${item}_${idx}`"
          >
            - {{ item }}
          </div>
        </span>
        <span v-else-if="props.column.field == 'dataTenDonViLayDuLieu'">
          <div
            v-for="(item, idx) in props.row.dataTenDonViLayDuLieu"
            :key="`${item}_${idx}`"
          >
            - {{ item }}
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </div>
      <div
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'tenBaoCao'">
          <treeselect
            id="linhVuc"
            ref="tenBaoCao"
            v-model="payload.baoCaoId"
            v-format-v-select
            :default-options="bcCbx"
            placeholder="Chọn báo cáo"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            class="vtreeselect-chooser"
            :normalizer="normalizerBaoCao"
            :match-keys="['label', 'label2']"
            :load-options="onChange"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenBaoCao.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
        <span v-else-if="props.column.field == 'tenDonVi'">
          <treeselect
            v-model="payload.donViId"
            v-format-v-select
            :options="dvCbx"
            class="vtreeselect-chooser"
            placeholder="Chọn vai trò"
            :normalizer="normalizer"
            :match-keys="['label', 'name']"
            :multiple="true"
            value-consists-of="LEAF_PRIORITY"
            :limit="0"
            :limit-text="count => `và ${count} vai trò`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            @input="columnFilter()"
          />
        </span>
      </div>
      <div
        slot="actions"
        slot-scope="{ props }"
      >
        <edit-icon
          v-if="checkQuyenSua"
          v-b-tooltip.hover.v-secondary
          title="Cập nhật"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="updateCommom(props.row)"
        />
        <trash-2-icon
          v-if="checkQuyenXoa"
          v-b-tooltip.hover.v-secondary
          title="Xóa"
          size="16"
          class="custom-class cursor-pointer"
          @click="deleteCommomTable(props.row)"
        />
      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  VBTooltip, BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/mot-cua/components/common/ActionBtn.vue'
import CommonModal from '@/modules/mot-cua/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import PhanQuyenBaoCaoDonViForm from '@/modules/mot-cua/components/form/PhanQuyenBaoCaoDonViForm.vue'
import XoaForm from '@/modules/mot-cua/components/form/XoaForm.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { removeDiacritical, compareObjects } from '@/utils/index'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'
import END_POINTS from '@/api/endpoints'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _debounce from 'lodash/debounce'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    BButton,
    GoodTable,
    PhanQuyenBaoCaoDonViForm,
    XoaForm,
    EditIcon,
    Trash2Icon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới phân quyền báo cáo - đơn vị',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên báo cáo',
          field: 'tenBaoCao',
          width: '250px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên vai trò',
          field: 'dataTenVaiTro',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên đơn vị',
          field: 'dataTenDonViLayDuLieu',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: [],
      bcCbx: [],
      dvCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        baoCaoId: null,
        donViId: null,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        baoCaoId: null,
        donViId: null,
        vaiTro: null,
        donViLayDuLieu: null,
        dataVaiTro: [],
        dataDonViLayDuLieu: [],
      },
      beginObject: {},
      isLoading: false,
      size: null,
      isShow: false,
      isShowFooter: false,
      idDelete: null,
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataPhanQuyenBaoCaoDonVi()
    this.getDataBcCbx()
    this.getDataDonVi()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    normalizerBaoCao(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.bcCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới phân quyền báo cáo - đơn vị'
      this.componentName = 'PhanQuyenBaoCaoDonViForm'
      this.size = 'lg'
      this.dataForm = {
        baoCaoId: null,
        donViId: null,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataBcCbx() {
      this.$axios.get(END_POINTS.BAO_CAO.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.bcCbx = res.data.data
        }
      })
    },
    getDataDonVi() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI, { kieuDonVi: 1 }).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dvCbx = this.listToTree(formatDonVi)
        }
      })
    },
    getDataPhanQuyenBaoCaoDonVi() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.BAO_CAO_DON_VI.DS, {
          ...this.payload,
          baoCaoId: this.payload.baoCaoId?.map(item => ({
            id: item,
          })),
          donViId: this.payload.donViId?.map(item => ({
            id: item,
          })),
        }, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataPhanQuyenBaoCaoDonVi()
      })
    },
    refreshPage() {
      this.payload = {
        baoCaoId: null,
        donViId: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.getDataPhanQuyenBaoCaoDonVi()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          data: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.BAO_CAO_DON_VI.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataPhanQuyenBaoCaoDonVi()
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'PhanQuyenBaoCaoDonViForm') {
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.BAO_CAO_DON_VI.SUA, [this.dataForm]).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenBaoCaoDonVi()
              this.$refs.commonModal.hideModal()
            }
          })
        } else {
          this.$axios.post(END_POINTS.BAO_CAO_DON_VI.THEM, [this.dataForm]).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenBaoCaoDonVi()
              this.$refs.commonModal.hideModal()
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật phân quyền báo cáo - đơn vị'
      this.componentName = 'PhanQuyenBaoCaoDonViForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        baoCaoId: data.baoCaoId,
        donViId: data.donViId,
        dataVaiTro: data.dataVaiTroId,
        dataDonViLayDuLieu: data.dataDonViLayDuLieuId,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền báo cáo - đơn vị?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền báo cáo - đơn vị?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.BAO_CAO_DON_VI.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      const payload = {
        ...data,
        baoCaoId: this.payload.baoCaoId && this.payload?.baoCaoId.length > 0 ? this.payload?.baoCaoId.map(item => ({ id: item })) : [],
        donViId: this.payload.donViId && this.payload?.donViId.length > 0 ? this.payload?.donViId.map(item => ({ id: item })) : [],
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.payload = {
        ...data,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.BAO_CAO_DON_VI.DS, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          name: removeDiacritical(node.tenDonVi),
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
        name: removeDiacritical(node.tenDonVi),
      }
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['tenBaoCao', 'tenLinhVuc', 'tenVaiTro', 'vaiTroDuLieu', 'donViId'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
