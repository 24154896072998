<template>
  <div class="d-sm-flex justify-content-start">
    <div>
      <button
        class="compact ui button text-height"
        @click="onRefreshPage"
      >
        <refresh-cw-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Tải lại
      </button>
      <button
        v-if="actions.includes('createBtn') && actionsPermission[0]"
        class="compact ui button btn-primary text-height"
        @click="onAddCommom"
      >
        <plus-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Thêm mới
      </button>
      <button
        v-if="actions.includes('editorBtn')"
        class="compact ui button btn-primary"
        :class="selectedNumber !== 1 ? 'disabled' : ''"
        @click="onUpdate"
      >
        Cập nhật
      </button>
      <button
        v-if="actions.includes('ayncBtn')"
        class="compact ui button btn-primary"
      >
        Đồng bộ
      </button>
      <button
        v-if="actions.includes('editorProfileBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorProfile"
      >
        Cập nhật kiểu hồ sơ
      </button>
      <button
        v-if="actions.includes('editorFormBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorForm"
      >
        Cập nhật biểu mẫu
      </button>
      <button
        v-if="actions.includes('editorPaperBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorPaper"
      >
        Cập nhật giấy tờ
      </button>
      <button
        v-if="actions.includes('answerBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onAddAnswerCommom"
      >
        Trả lời
      </button>
      <button
        v-if="actions.includes('detailBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onAddCommom"
      >
        Chi tiết
      </button>
      <button
        v-if="actions.includes('deleteBtn') && actionsPermission[1]"
        class="compact ui button btn-primary text-height"
        :class="selectedNumber === 0 ? 'disabled' : ''"
        @click="onDelete"
      >
        <trash-2-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Xóa
      </button>
      <button
        v-if="actions.includes('pqmpBtn')"
        class="compact ui button btn-primary"
      >
        Phân quyền mẫu phiếu
      </button>
    </div>
  </div>
</template>
<script>
import addCssSemantic from '@/mixins/mixins'
import {
  RefreshCwIcon,
  Trash2Icon,
  PlusIcon,
} from 'vue-feather-icons'

export default {
  components: {
    RefreshCwIcon,
    Trash2Icon,
    PlusIcon,
  },
  mixins: [addCssSemantic],
  props: {
    actions: {
      type: Array,
      default: () => ['createBtn', 'deleteBtn'],
    },
    selectedNumber: {
      type: Number,
      default: 0,
    },
    actionsPermission: {
      type: Array,
      default: () => [true, true],
    },
  },
  data() {
    return {
      searchInput: null,
    }
  },
  computed: {
    isDisabelEditBtn() {
      return this.$isDisabelBtn !== 1
    },
    isDisabelDeleteBtn() {
      return this.$isDisabelBtn < 1
    },
  },
  created() {
    this.$isDisabelBtn = 0
  },
  methods: {
    onRefreshPage() {
      this.$emit('refresh-page')
    },
    onAddCommom() {
      this.$emit('add-item')
    },
    onUpdate() {
      if (this.selectedNumber === 1) {
        this.$emit('update-item')
      }
    },
    onDelete() {
      if (this.selectedNumber >= 1) {
        this.$emit('delete-item')
      }
    },
    onSearchCommon() {
      this.$emit('onSearchCommon', this.searchInput)
    },
    onEditorProfile() {
      this.$emit('onEditorProfile')
    },
    onEditorForm() {
      this.$emit('onEditorForm')
    },
    onEditorPaper() {
      this.$emit('onEditorPaper')
    },
    onDeleteCommom() {
      this.$emit('deleteCommom')
    },
    onAddAnswerCommom() {
      this.$emit('addAnswerCommom')
    },
  },
}
</script>
